<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
  <router-view/>
</template>

<style>
body {
  background-color: #000000;
  color: #ffffff;
  font-family: "Poppins", sans-serif;
}
</style>
